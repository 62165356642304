        <!-- carousel component -->
<div *ngIf="images && images.length > 0 " class="carousel-container">
    <img *ngFor="let image of images ; let i = index" 
         class="fade" 
         [src]="image.imageSrc" 
         [alt]="image.imageAlt"
         [ngClass]="{'active-class': selectedIndex === i}"
          />
          <!-- dot indicator -->
    <div *ngIf="indicators" class="carousel-dot-container">
        <span *ngFor="let dot of images; let i = index"
         class="dot"
         [ngClass]="{'active': selectedIndex === i}"
         (click)="selectedImage(i)"
         >
        </span>
       
    </div>
    <!-- arrow  -->
    <button *ngIf="prevNextButtons" [ngClass]="{'shadowPrev': arrowLeft}" (click)="onPressPrev()" class="btn-carousel btn-prev" (mouseenter)="arrowLeft = true" (mouseleave)="arrowLeft = false">
        <mat-icon *ngIf="arrowLeft" >arrow_back_ios</mat-icon>
    </button>
    <button *ngIf="prevNextButtons" [ngClass]="{'shadowNext': arrowRight}" class="btn-carousel btn-next" (click)="onPressNext()"  (mouseenter)="arrowRight = true" (mouseleave)="arrowRight = false">
        <mat-icon *ngIf="arrowRight">arrow_forward_ios</mat-icon>
    </button>
</div>

import { AfterViewInit, Component, Input } from '@angular/core';
import { ImageCarousel } from 'app/main/shared/models/imageCarousel';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements AfterViewInit {
    @Input() images: ImageCarousel[] = [];
    @Input() indicators;
    @Input() prevNextButtons  = true;
    @Input() autoSlide = false;
    @Input() interval = 4500;
    selectedIndex = 0;

    ngAfterViewInit(){
        this.autoSlideImages();
    }
    // auto slide images after interval
    autoSlideImages(){
        if(this.autoSlide){
            setInterval(() => {
                this.onPressNext();
            }, this.interval);
        }
    }
    // select image from indicator dots 
    selectedImage(index : number){
        this.selectedIndex = index;
    }
    // select image from prev and next buttons
    onPressPrev(){
        if(this.selectedIndex > 0){
            this.selectedIndex--;
        } else {
            this.selectedIndex = this.images.length - 1;
        }
    }
    onPressNext(){
        if(this.selectedIndex < this.images.length - 1){
            this.selectedIndex++;
        } else {
            this.selectedIndex = 0;
        }
    }
 }

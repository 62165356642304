import { NgModule } from '@angular/core';
import { HomePageComponent } from './home-page.component';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel/carousel.component';
import { MaterialModule } from '../../material/material.module';


@NgModule({
    declarations: [HomePageComponent, CarouselComponent],
    imports: [
        CommonModule,
        MaterialModule
    ],
    exports: [HomePageComponent],
})
export class HomePageModule { }